*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
 
}
.headercomponent {
    
    text-align: center;
    margin-bottom: 20px;

    
  }

 
.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    padding-top: 5px;
    
  }
  
  .image-container {
    display: inline-block;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add the shadow effect */
    padding: 10px; /* Optional: Add padding around the image */
  }
  
  .image-with-border {
    width: 80%; /* Set the desired width for the image */
    border: 2px solid #000; /* Add a border around the image */
    border-radius: 5px; /* Optional: Add border radius for a rounded corner effect */
    margin-left: 10%;
  }
  
  .text-container {
    display: inline-block;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add the shadow effect */
    padding: 10px; /* Optional: Add padding around the image */
    border-radius: 10%;
    margin-top: 15px;
  }
  
  .text-with-border {
    size: 10px;/* Set the desired width for the text */
    border: 2px solid #000; /* Add a border around the text */
    border-radius: 5px /* Optional: Add border radius for a rounded corner effect */
  }


  * {
    box-sizing: border-box;
  }

  .wrapper {
    max-width: 50rem;
    width: 100%;
    margin: 0 auto;
  }
  .tabs {
    position: relative;
    margin: 3rem 0;
    background: #c2adad;
    height: 14.75rem;
    background-image: url("../../assets/tab_back.png");
  }
  .tabs::before,
  .tabs::after {
    content: "";
    display: table;
  }
  .tabs::after {
    clear: both;
  }
  .tab {
    float: left;
  }
  .tab-switch {
    display: none;
  }
  .tab-label {
    position: relative;
    display: block;
    line-height: 2.75em;
    height: 3em;
    padding: 0 1.618em;
    background: #2e0421;
    border-right: 0.125rem solid #cdebe5;
    color: #fff;
    cursor: pointer;
    top: 0;
    transition: all 0.25s;
  }
  .tab-label:hover {
    top: -0.25rem;
    transition: top 0.25s;
  }
  .tab-content {
    height: 12rem;
    position: absolute;
    z-index: 1;
    top: 2.75em;
    left: 0;
    padding: 1.618rem;
    background: #fff;
    color: #2c3e50;
    border-bottom: 0.25rem solid #bdc3c7;
    opacity: 0;
    transition: all 0.35s;
  }
  .tab-switch:checked + .tab-label {
    background: #fff;
    color: #2c3e50;
    border-bottom: 0;
    border-right: 0.125rem solid #fff;
    transition: all 0.35s;
    z-index: 1;
    top: -0.0625rem;
  }
  .tab-switch:checked + label + .tab-content {
    z-index: 2;
    opacity: 1;
    transition: all 0.35s;
  }
  .three-columns-grid {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}

/* columns */
.three-columns-grid > * {
    padding:1rem;
}
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
figure.snip1157 {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: none !important;
}
figure.snip1157 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
figure.snip1157 img {
  max-width: 100%;
  vertical-align: middle;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin: 40px 0 0 10px;
}
figure.snip1157 blockquote {
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: #fafafa;
  padding: 25px 50px 30px 50px;
  font-size: 0.8em;
  font-weight: 500;
  margin: 0;
  line-height: 1.6em;
  font-style: italic;
}
figure.snip1157 blockquote:before,
figure.snip1157 blockquote:after {
  font-family: 'FontAwesome';
  content: 201C;
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}
figure.snip1157 blockquote:before {
  top: 25px;
  left: 20px;
}
figure.snip1157 blockquote:after {
  content: 201D;
  right: 20px;
  bottom: 0;
}
figure.snip1157 .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #fafafa;
  margin: 0;
  position: absolute;
}
figure.snip1157 .author {
  position: absolute;
  bottom: 45px;
  padding: 0 10px 0 120px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
figure.snip1157 .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
}
figure.snip1157 .author h5 span {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
}


/* Demo purposes only */

.tabborder{
  background-image: url("../../assets/testback.jpg");

}

/* container */
feature-compare {
  display:flex;
  margin-top:2.5em;
}

/* columns */
feature-compare > * {
  position:relative;
  padding:0;
  text-align:center;
  width:25%;
}
feature-compare > :nth-child(2) {
  width:50%;
 
  box-shadow:0 5px 10px rgba(0, 0, 0, 25%);
}
feature-compare > :nth-child(2):before {
  content:"Most Popular";
  display:block;
  position:absolute;
  top:-2em;
  left:0;
  right:0;
  line-height:2em;
  background:#000;
  color:#fff;
  border-top-left-radius:.4em;
  border-top-right-radius:.4em;
}
free-level {
  background:#141818;
}
standard-level {
  background:#141818;
}
pro-level {
  background:#141818;
}

/* features */
feature-compare ul {
  list-style:none;
  margin:0;
  padding:0;
  background:#fff;
}
feature-compare li {
  white-space:nowrap;
  line-height:2.2em;
  border-top:1px solid #eee;
}
feature-compare li:first-child {
  border-top:0;
}
feature-compare li.tick:before {
  content: '✓ ';
  color:#006e37;
  font-weight:bold;
  font-size:1.3em;
}
feature-compare li.cross:before {
  content: '✗ ';
  color:#ec1358;
  font-weight:bold;
  font-size:1.3em;
}
feature-compare h3 {
  margin:0;
  padding:.4em 0 0;
}
feature-compare .price {
  margin:0 0 .2em;
  font-size:2em;
  font-weight:bold;
}
feature-compare span {
  display:none;
}

/* buttons */
feature-compare .button {
  display:inline-block;
  margin:.5em 0;
  padding:.4em .5em;
  background:#fff;
  color:#000;
  text-decoration:none;
  font-weight:bold;
  border-radius:.4em;
}
feature-compare > :nth-child(2) .button {
  background:#000;
  color:#fff;
}

/* tablet breakpoint */
@media (min-width:900px) {
  feature-compare {
      margin:2.5em calc(8px + 1.5625vw);
  }
  feature-compare > * {
      width:calc(100% / 3);
  }
  feature-compare span {
      display:inline;
  }
}



.column1 {
  float: left;
  width: 33%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
  margin-top: 9%;
 
}

/* Clear floats after the columns */
.row1:after {
  content: "";
  display: table;
  clear: both;

}

/* Responsive layout - makes the four columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column1 {
    width: fit-content;
    align-content: center;
    
  }
}


/* Container holding the image and the text */
.container1 {
  position: relative;
  width: 100%;
  top: 30%;
  left:50%;
 
  transform: translate(-50%,-50%) ;
 
  border: 1px solid rgb(137, 205, 236);



}

@media screen and (max-width: 760px){
  .container1{ width: 100%; margin: 0; padding: 0; }
}

.image1 {
  display: block;
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  background-color:black;
  cursor: pointer;
}

.content {

  position: absolute;
  top :50%;
  left :50%;
  transform : translate(-50% , -50%);
  color :white
  
}

.container1:hover .overlay{
  opacity: 0.8;
}

/* Bottom left text */

/* Centered text */
.header-alignment
{
  text-align: center;
  margin-top: 2%;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  margin-bottom: 2%;

  
}
.subheader-alignment
{
  text-align: center;
  margin-top: 2%;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  color: rgb(40, 198, 226);
  margin-bottom: 2%;


}
.para{
  text-align: center;
  margin-top: 2%;
  font-family: sans-serif;
  text-size-adjust: 5;
  text-shadow: 1px 1px 2px black;
}

.header_style
{
  box-shadow: rgba(58, 216, 124, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}


.column2 {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row2:after {
  content: "";
  display: table;
  clear: both;
}
@media screen and (max-width: 600px) {
  .column2 {
    width: 100%;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .header-alignment {
    margin-bottom: 10%;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
}

.price {
  color: grey;
  font-size: 22px;
}

.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.card button:hover {
  opacity: 0.7;
}
.spacing
{
  margin-top: 10%;
  margin-bottom: 10%;
}

@media screen and (max-width: 600px) {
  .main_container{
    
    min-width: 100%;

    
  }
} 
.boxes_1 {
  width:50%;
  margin:0 auto;
  ;
}
.box_1 {
  display:inline-block;
  width:25%;
  height:15em;
  text-align:center;
  padding: 1.5%;
  font-size: medium;
  box-shadow: 5px 10px #888888;
  margin-left: 2%;
  color:#fff;
  text-align: center;
 
}
.one_1 { background:#333;	}
.two_1 { 
  background:#55c9c3;
  color:#631515;
}
.three_1 { background:#aaa; }
.four_1 { background:#dc002e; }

/* 768px */
@media screen and (max-width:48em) {
 .boxes_1 { 
   display:table;
   width:100%;
  
 }
 .box_1 {
   display:block;
   width:100%;
   
 }
 
}
.box_container{
    display: flex;
    flex-direction: column;
    text-align: center;
   
  
}
