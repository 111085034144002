@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

.nav {
  height: 50px;
  width: 100%;
  padding: 0px 20px;
  background-color: #3795a3;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 4;
	left: 0;
	right: 0;
	top: 0;
	font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  padding: 0 5%;
	height: 80px;
}
.nav > .nav-header {display: inline;}
.nav > .nav-header > .nav-title {
  display: inline-block;
  font-size: 22px;
  color: #fff;
  padding: 10px 10px 10px 10px;
}
.nav > .nav-btn {display: none;}
.nav > .close-btn {display: none;}
.nav > .nav-links {display: inline;float: right;font-size: 18px;}
.nav > .nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #efefef;
}
.nav > .nav-links > a:hover {background-color: rgba(0, 0, 0, 0.3);}
.nav > #nav-check {display: none;}


@media (max-width:600px) {

  
  .nav > .close-btn {display: none;}
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .nav > .nav-links {

    position: absolute;
    display: block;
    width: 50%;
    background-color: #266872;
    background:rgba(36, 57, 70, 0.7);
    height: 0px;
    
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 100%;
    left: 0px;
    bottom: 20%;
  }
  
  .nav > .nav-links > a {display: block;width: 100%;}
  .nav > #nav-check:not(:checked) ~ .nav-links {height: 0px;}
  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
    
    
  }
  .nav > #nav-check:checked ~ .nav-btn {
    display: none;
    
    
  }
  
  

  .nav > #nav-check:checked ~ .close-btn {
    display: block;
    
  }
  .nav > #nav-uncheck:checked ~ .close-btn {
    display: none;
    
  }
  
  
 
}

.nav > .nav-links > a.active {background-color: #77c6da;}
/* CSS for the visible navbar */
.navbar-visible {
  display: block;
}

/* CSS for the hidden navbar */
.navbar-hidden {
  display: none;
}
.boxrt {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  transition: background-color 0.3s ease;
}

.clicked {
  display: none;/* Change to your desired color */
}






