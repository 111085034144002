.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 10%;
}

.faq-list {
  margin-top: 20px;
}

.faq-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.faq-item.active {
  background-color: #f2f2f2;
}

.faq-question {
  font-weight: bold;
}

.faq-answer {
  display: none;
  margin-top: 10px;
}

.faq-item.active .faq-answer {
  display: block;
}
