/* .hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: url("../../assets/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: brightness(60%);
}

.hero {
  border: 2px solid blue;
  position: relative;
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-background {
  position: relative; 
  font-family: "Monserrat", sans-serif;
  color: white;
  text-align: center;
  margin: 0.625rem;
  border: 2px solid red;
}
.hero-title {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0;
  border: 2px solid green;
}
.hero-subtitle {
  font-size: 2rem;
  font-weight: 200;
  margin-top: 1rem;
  border: 2px solid purple;
}


.trial-btn {
  background-color: #ae2d59;
  color: white;
  width: auto;
  border: none;
  margin-top: 50rem;
  padding: 0.9375rem 1.875rem;
  font-size: 1.125rem;
  font-weight: 200;
  cursor: pointer;
  border-radius: 3rem;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px;
  border: 2px solid orange;
} */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');



.hero {
  position: relative;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 
  
}

.hero-background {
  background-image: url("../../assets/Background.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.hero-title {
  font-size: 2.5rem;
  color: #ffffff;
  text-align: center;
  padding-top: 8vh;
  margin-bottom: 1.5rem;
  font-weight: bold;
  margin-top: 5%;
  font-family: 'Roboto Condensed', sans-serif;

}

.hero-subtitle {
  font-size: 1.25rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
}

.trial-btn {
  display: flex;
  justify-content: center;
  
  
  font-size: 16px;
  margin: 4px 2px;
  margin-left: 1%;
  margin-top: 6%;
}
.button2 {background-color: #ffffff;}

.trial-btn a {
  margin-top: 5px;
  background-color: #06BBCC;
  color: white;
  border: none;
  padding: 0.9375rem 1.875rem;
  font-size: 1.125rem;
  font-weight: 200;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0px;
  transition: background-color 0.3s, transform 0.3s;
}

.trial-btn a:hover {
  background-color: #266872;
  transform: scale(1.05);
}

.trial-btn a:active {
  transform: scale(0.95);
}


.text-container {
  display: inline-block;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add the shadow effect */
  padding: 10px; /* Optional: Add padding around the image */
  border-radius: 10%;
  margin-top: 15%;
  background-color: beige;
  text-align: center;
 
  margin: auto;
 
  
  
}
.testimonial-section {
  display: inline-block;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add the shadow effect */
  padding: 10px; /* Optional: Add padding around the image */
  border-radius: 10%;
  margin-top: 15%;
  
  background-color: beige;

  
}

.container {
  border: 2px solid #ccc;
  background-color: #eee;
  border-radius: 5px;
  padding: 16px;
  margin: 16px 0;
}

/* Clear floats after containers */
.container::after {
  content: "";
  clear: both;
  display: table;
}

/* Float images inside the container to the left. Add a right margin, and style the image as a circle */
.container img {
  float: left;
  margin-right: 20px;
  border-radius: 50%;
}

/* Increase the font-size of a span element */
.container span {
  font-size: 20px;
  margin-right: 15px;
}

/* Add media queries for responsiveness. This will center both the text and the image inside the container */

  .container img {
    margin: auto;
    float: none;
    display: block;
  }

@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

.snip1533 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #9e9e9e;
  display: inline-block;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 16px;
  margin: 35px 10px 10px;
  max-width: 310px;
  min-width: 250px;
  position: relative;
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  border-top: 5px solid black;
}

.snip1533 *,
.snip1533 *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.snip1533 figcaption {
  padding: 13% 10% 12%;
}

.snip1533 figcaption:before {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color:black;
  content: "\f10e";
  font-family: 'FontAwesome';
  font-size: 32px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  position: absolute;
  top: -30px;
  width: 60px;
}

.snip1533 h3 {
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin: 10px 0 5px;
}

.snip1533 h4 {
  font-weight: 400;
  margin: 0;
  opacity: 0.5;
}

.snip1533 blockquote {
  font-style: italic;
  font-weight: 300;
  margin: 0 0 20px;
  
}


/* Demo purposes only */

.row {
  padding: 0 15px;
}




/* Create four equal columns that floats next to each other */
.column {
  float: left;
  width: 25%;
 
  ;
  /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the four columns stack on top of each other instead of next to each other */
@media screen and (max-width: 700px) {
  .column {
   
    width:25%;
   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
   
  }
}



/* Responsive layout - makes the four columns stack on top of each other instead of next to each other */



/* skill section
/* Icon set - http://ionicons.com/ */
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
figure.snip1139 {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: #333;
 
  text-align: left;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
figure.snip1139 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

figure.snip1139 blockquote {
  position: relative;
  background-color: #d2f4f8;
  padding: 25px 50px 25px 50px;
  font-size: 0.8em;
  font-weight: 500;
  text-align: left;
  margin: 0;
  line-height: 1.6em;
  font-style: italic;
}
figure.snip1139 blockquote:before,
figure.snip1139 blockquote:after {
  font-family: 'FontAwesome';
 
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}
figure.snip1139 blockquote:before {
  top: 25px;
  left: 20px;
}
figure.snip1139 blockquote:after {

  right: 20px;
  bottom: 0;
}
figure.snip1139 .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #ffffff;
  margin: 0;
  position: absolute;
}
figure.snip1139 .author {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px 25px;
  color: #000000;
  background-color: #ffffff;
  margin: 0;
  text-transform: uppercase;
}
figure.snip1139 .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
}
figure.snip1139 .author h5 span {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
}

.btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  
}

/* Darker background on mouse-over */
.btn:hover {
  background-color: RoyalBlue;
}
.allign {
  background-color: RoyalBlue;
 
}

.testimonial_head{

text-align: center;
justify-items: auto;
margin-top: 2%;

font-family: 'Roboto Condensed', sans-serif;
font-weight: 700;


}
.notice_head{

  text-align: center;
  justify-items: auto;
  margin-top: 2%;
  
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  
  
  }

@media screen and (max-width: 600px) {
  .testimonial_head {
    text-align: center;
   
    text-size-adjust: 700;
  }
}


.boxes {
  width:50%;
  margin:0 auto;
}
.box {
  display:inline-block;
  width:100%;
  height:15em;
  text-align:center;
  
  color:#fff;
}
.one { background:#92cad8;	}
.two { 
  background:#258b99;
  color:#333;
}
.three { background:#88ccd4; }
.four { background:#7c3443; }

/* 768px */
@media screen and (max-width:48em) {
 .boxes { 
   display:table;
   width:100%;
 }
 .paragraph{
  text-align: center;
 
 }
 .box {
   display:inline-block;
   width:100%;
 }
 .three { display:table-caption; }
 .four { display:table-header-group; }
 .one { display:table-footer-group; }

}
.paragraph{
  margin-top: 10%;
}