@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
   
  }

  .contact-info{
    color: #180404;
    max-width: 500px;
    line-height: 65px;
    padding-left: 50px;
    font-size: 18px;
  }
  
  .column_cont {
    float: left;
    width: 50%;
  }
  
  /* Clear floats after the columns */
  .row_cont:after {
    content: "";
    display: table;
    clear: both;
  }

  @media screen and (max-width: 600px) {
    .column_cont {
      width: 100%;
    
    }
    
  }
  @media screen and (max-width: 600px) {
    .img_1 {
      width: 100%;
    
    }
    
  }



  .row_cont{
    margin-top: 2%;
  }
  .Header_cont{
    margin-top: 2%;
  }